import React, { useEffect, useRef } from "react";
import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import Slider from "react-slick";

const SocFeatureExplanation = ({ fields, progress }) => {
  const carouselContainerRef = useRef();
  const sliderRef = useRef();
  const activeSlideIndex = useRef();
  const featureTypes = ["LF", "TF", "BF"];
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    let newActiveIndex = 0;
    const sliderLength = featureTypes.length;
    [...featureTypes, 1].some((_, index) => {
      if (progress <= index / sliderLength) {
        newActiveIndex = index - 1;
        return index;
      }
    });
    if (newActiveIndex !== activeSlideIndex.current) {
      sliderRef.current.slickGoTo(newActiveIndex);
      activeSlideIndex.current = newActiveIndex;
    }
  }, [progress]);

  return (
    <div
      ref={carouselContainerRef}
      className={`component soc-feature-explanation ${fields?.["css classes"]?.value}`}
    >

      <div className="slider">

        <Slider {...settings} ref={sliderRef} className="soc-slick-slider">
          {featureTypes.map((item, index) => (
            <div>
              <div className={`row g-6 mt-0`} key={item}>
                <div className="col-12 col-md-6 d-flex align-items-center mt-0">
                  <Image
                    className="feature-image"
                    field={fields[item + " - Image"]}
                  />
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center mt-0">
                  <RichText
                    className="feature-explanation"
                    field={fields[item + " - Text"]}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="flat-list">
        {featureTypes.map((item, index) => (
          <div className="single-item">
            <div className={`row g-6 mt-0`} key={item}>
              <div className="col-12 col-md-6 d-flex align-items-center mt-0">
                <Image
                  className="feature-image"
                  field={fields[item + " - Image"]}
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center mt-0">
                <RichText
                  className="feature-explanation"
                  field={fields[item + " - Text"]}
                />
              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
};

export default SocFeatureExplanation;
